import React from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import Header from "../components/GlobalComponents/Header/Header";
import Footer from "../components/GlobalComponents/Footer/Footer";
import Banner from "../components/AboutPage/Banner/Banner";
import TradersChoose from "../components/AboutPage/TradersChoose/TradersChoose";
import Commits from "../components/AboutPage/Commits/Commits";
import MakesDifference from "../components/AboutPage/MakesDifference/MakesDifference";
import SeeWhat from "../components/AboutPage/SeeWhat/SeeWhat";
import Docs from "../components/AboutPage/Docs/Docs";

const AboutPage = () => {
  return (
    <HelmetProvider>
      <Helmet>
        <title>Lumina · About Us</title>
        <meta name="title" content="About Lumina - Empowering Your Financial Journey" />
        <meta property="og:title" content="About Lumina - Empowering Your Financial Journey" />
        <meta property="twitter:title" content="About Lumina - Empowering Your Financial Journey" />

        <meta name="description" content="Learn about Lumina - our mission, values, and commitment to empowering traders. Discover the story behind our platform and why thousands choose Lumina for a secure and enriching trading experience." />
        <meta property="og:description" content="Learn about Lumina - our mission, values, and commitment to empowering traders. Discover the story behind our platform and why thousands choose Lumina for a secure and enriching trading experience." />
        <meta property="twitter:description" content="Learn about Lumina - our mission, values, and commitment to empowering traders. Discover the story behind our platform and why thousands choose Lumina for a secure and enriching trading experience." />

        <meta property="og:image" content="preview.png" />
        <meta property="og:type" content="website" />
        <meta property="twitter:image" content="preview.png" />
        <meta property="twitter:card" content="summary_large_image" />
      </Helmet>
      <div className="ovf-hidden">
        <Header />
        <Banner />
        <TradersChoose />
        <Docs />
        <Commits />
        <MakesDifference />
        <SeeWhat />
        <Footer />
      </div>
    </HelmetProvider>
  );
};

export default AboutPage;
