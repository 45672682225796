import React, { useState } from "react";
import classes from './Banner.module.scss';
import { useTranslation } from "react-i18next";
import Button from "../../GlobalComponents/Button/Button";
import Input from "../Input/Input";
import Phone from "../Input/Phone";
import Textarea from "../Input/Input copy";

const Banner = () => {
    const { t } = useTranslation();
    const { hostname } = document.location;

    const [name, setName] = useState('');
    const [country, setCountry] = useState('');
    // eslint-disable-next-line
    const [phone, setPhone] = useState('');
    const [email, setEmail] = useState('');
    const [additional, setAdditional] = useState('');

    const submit = () => {
        alert('Submit');
    };

    return (
        <section className={classes.container}>
            <div className="container">
                <div className={classes.wrap}>
                    <div className={classes.formWrap}>
                        <h2 className={`${classes.title} font-36`}>
                            {t('contact.title')}
                        </h2>
                        <div className={classes.form}>
                            <div className={classes.inputs}>
                                <div className={classes.group}>
                                    <Input
                                        value={name}
                                        setValue={setName}
                                        valid={true}
                                        type="text"
                                        placeholder={t('contact.form.name')}
                                    />
                                </div>
                                <div className={classes.group}>
                                    <Input
                                        value={country}
                                        setValue={setCountry}
                                        valid={true}
                                        type="text"
                                        placeholder={t('contact.form.country')}
                                    />
                                </div>
                                <div className={classes.group}>
                                    <Phone
                                        setValue={setPhone}
                                        valid={true}
                                    />
                                </div>
                                <div className={classes.group}>
                                    <Input
                                        value={email}
                                        setValue={setEmail}
                                        valid={true}
                                        type="email"
                                        placeholder={t('contact.form.email')}
                                    />
                                </div>
                                <div className={classes.group}>
                                    <Textarea
                                        value={additional}
                                        setValue={setAdditional}
                                        placeholder={t('contact.form.additional')}
                                    />
                                </div>
                            </div>
                            <div className={classes.submit}>
                                <Button event={submit} dark>
                                    {t('btn.send')}
                                </Button>
                            </div>
                        </div>
                    </div>
                    <div className={classes.contacts}>
                        <div className={`${classes.items} font-16`}>
                            <div className={classes.item}>
                                <p className={classes.itemTitle}>
                                    {t('contact.registered_head_office')}
                                </p>
                                <p className={classes.itemText}>
                                    Flat 7 Lampard House, 8 Maida Avenue, London, England, W2 1SS
                                </p>
                            </div>
                            <div className={classes.item}>
                                <p className={classes.itemTitle}>
                                    {t('contact.telephone_number')}
                                </p>
                                <a href="tel:+441241340848" className={classes.itemText}>
                                    +441241340848
                                </a>
                            </div>
                            <div className={classes.item}>
                                <p className={classes.itemTitle}>
                                    {t('contact.form.email')}
                                </p>
                                <a href={`mailto:support@${hostname}`} className={classes.itemText}>
                                    support@{hostname}
                                </a>
                            </div>
                        </div>
                        <p className={`${classes.text} font-20`}>
                            {t('contact.text')}
                        </p>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Banner;
